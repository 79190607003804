<template>
    <div class="doctor-component">
        <!-- Doctor Component -->
        <div class="d-flex flex-column">
            <div class="pa-2 pa-sm-4 mr-4 my-2 d-flex rounded-lg light-grey-border white-background" v-for="doctor in doctor_data">
                <!-- Doctor Image -->
                <div class="d-flex justify-center align-center">
                    <img class="rounded doctor-image" :src="doctor.icon" alt="Product Image">
                </div>

                <!-- Doctor Info -->
                <div class="pl-4 d-flex flex-column justify-space-between full-width-button">
                    <!-- Doctor Name -->
                    <div>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <p class="ma-0 body-heading font-weight-semi-bold line-clamp doctor-name"
                                    v-bind="attrs"
                                    v-on="on"
                                    align="left"
                                >{{ doctor.name }}</p>
                            </template>
                            <span>{{ doctor.name }}</span>
                        </v-tooltip>
                    </div>

                    <!-- Other Data -->
                    <div class="d-flex flex-column">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <p class="ma-0 pb-1 body-text light-grey-color-text font-weight-medium line-clamp-one-line"
                                    v-bind="attrs"
                                    v-on="on"
                                    align="left"
                                >{{ doctor.specialization_name }}</p>
                            </template>
                            <span>{{ doctor.specialization_name }}</span>
                        </v-tooltip>
                        
                        <p class="ma-0 py-1 body-heading font-weight-semi-bold light-blue-color-text" align="left">{{ doctor.price }} KS</p>
                        <button class="mt-1 px-2 py-1 rounded light-blue-background full-width-button button-class" @click="redirectTo('newBookingPage', doctor._id)">
                            <span class="body-text font-weight-medium white-color-text">{{ $t("Customer.Home.View_Slot") }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DoctorHomeComponent2',
    props: {
        doctor_data: {
            type: Array,
            required: true
        }
    },
    data() {
        return {

        };
    },
    mounted() {

    },
    methods: {
        redirectTo(pageName, doctorId) {
            if (pageName == 'newBookingPage') {
                window.open(window.location.origin + '/customer/newBookingPage/' + doctorId + '&' + false);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/classes.scss';
@import '../../scss/global.scss';
.doctor-component {
    @media (max-width: 600px) {
        width: 330px;
    }

    @media (min-width: 601px) and (max-width: 1264px) {
        width: 350px;
    }

    @media (min-width: 1265px) {
        width: 360px;
    }
}
.doctor-image {
    object-fit: fill;

    @media (max-width: 600px) {
        width: 130px;
        height: 130px;
    }

    @media (min-width: 601px) and (max-width: 1264px) {
        width: 150px;
        height: 150px;
    }

    @media (min-width: 1265px) {
        width: 150px;
        height: 150px;
    }
}

.doctor-name {
    width: 100%;
    height: 48px;
}

.line-clamp {
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-clamp-one-line {
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>

<style lang="scss">
@import '../../scss/classes.scss';
@import '../../scss/global.scss';
</style>